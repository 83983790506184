<template>
  <div style="height: 100%; background: #2a394b">
    <div
      style="
        padding-top: 8px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        class="logo"
        :style="{ 'margin-right': !isCollapse ? '10px' : '0' }"
        style="width: 80px; height: 40px; text-align: center"
      >
        <!-- <el-image style="" :src="logo"></el-image> -->
        <el-image style="" :src="img" fit="fill"></el-image>
      </div>
      <div v-if="!isCollapse" class="title" style="font-size: 16px">
        {{ name }}
      </div>
    </div>
    <el-menu
      background-color="#2a394b"
      text-color="#FFFFFF"
      class="menu"
      :collapse="isCollapse"
      router
      :default-active="defaultActive"
    >
      <menu-item
        v-for="route in siderbarRouters"
        :key="route.id"
        :item="route"
      ></menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import logo from "@/assets/images/logo.jpg";
import MenuItem from "./cpns/MenuItem.vue";
export default {
  components: { MenuItem },
  data() {
    return {
      name: "",
      img: "",
    };
  },
  created() {
    this.name = process.env.VUE_APP_NAME;
    this.img = require(process.env.VUE_APP_IMAGE);
  },
  computed: {
    isCollapse() {
      return this.$store.state.config.isCollapse;
    },
    defaultActive() {
      return this.$route.path;
    },
    logo() {
      return logo;
    },
    ...mapGetters(["siderbarRouters"]),
  },
};
</script>

<style lang="scss" scoped>
.menu:not(.el-menu--collapse) {
  width: 200px;
}
.el-menu-item {
  background: #031624 !important;
}
.el-menu {
  border-right: none !important;
}
</style>
